<template>
    <main id="liste" v-cloak>
        <HeaderTab :title="$t('compta.title_page')" />

        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div>
                            <Accounting />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script type="text/javascript">

    export default {
        name: "AccountingListe",
        mixins: [],
        data () {
            return {
            }
        },

        mounted() {
            this.init_component()
        },

        methods: {
            async init_component() {
                //
            }
        },
        components: {
            HeaderTab: () => import('@/components/HeaderTab'),
            Accounting: () => import('@/components/Accounting/Accounting'),
        }
    }
</script>
